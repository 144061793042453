class ImageHelper {
	async convertAudioToBase64 (audioFile) {
		return new Promise( resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(audioFile)
			reader.onload = function(e) {
				const srcEncoded = e.target.result
				resolve(srcEncoded)
			}
		})
	}

	async compressImage (imageFile) {
		return new Promise( resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(imageFile)
			reader.onload = function(e) {
				//const imageElem = document.createElement('img')
				//imageElem.src = e.target.result
				const srcEncoded = e.target.result
				resolve(srcEncoded)
				// imageElem.onload = function (event) {
				// 	const canvas = document.createElement('canvas')
				// 	const MAX_WIDTH	= 400
				// 	const scaleSize = MAX_WIDTH / event.target.width
				// 	canvas.width = MAX_WIDTH
				// 	canvas.height = event.target.height * scaleSize
				// 	const ctx = canvas.getContext('2d')
				// 	ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height)
				// 	const srcEncoded = ctx.canvas.toDataURL(event.target, "image/jpeg")
				// 	//document.querySelector('#previewImage').src = srcEncoded
				// 	resolve(srcEncoded)
				// }
			}
		})
	}

	async compressServerImage (imageFile) {
		return new Promise( resolve => {
			const reader = new FileReader()
			reader.readAsDataURL(imageFile)
			reader.onload = function(e) {
				const imageElem = document.createElement('img')
				imageElem.src = e.target.result
				const srcEncoded = e.target.result
				resolve(srcEncoded)
				imageElem.onload = function (event) {
					const canvas = document.createElement('canvas')
					const MAX_WIDTH	= 400
					const scaleSize = MAX_WIDTH / event.target.width
					canvas.width = MAX_WIDTH
					canvas.height = event.target.height * scaleSize
					const ctx = canvas.getContext('2d')
					ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height)
					const srcEncoded = ctx.canvas.toDataURL(event.target, "image/jpeg")
					//document.querySelector('#previewImage').src = srcEncoded
					resolve(srcEncoded)
				}
			}
		})
	}

	async compressBase64Image (base64String) {
		return new Promise( resolve => {
			const imageElem = document.createElement('img')
			imageElem.src = base64String

			imageElem.onload = function (event) {
				const canvas = document.createElement('canvas')
				const MAX_WIDTH	= 400
				const scaleSize = MAX_WIDTH / event.target.width
				canvas.width = MAX_WIDTH
				canvas.height = event.target.height * scaleSize
				const ctx = canvas.getContext('2d')
				ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height)
				const srcEncoded = ctx.canvas.toDataURL(event.target, "image/jpeg")
				//document.querySelector('#previewImage').src = srcEncoded
				resolve(srcEncoded)
			}
		})
	}
}
const imageHelper = new ImageHelper()
export { imageHelper }
