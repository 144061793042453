import { createRouter, createWebHistory } from 'vue-router'
import { storageService } from '../services/storage.service'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Page/Login.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/panel/',
    component: () => import('../views/Page/Panel.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../views/Page/Dashboard.vue')
      },
      {
        path: 'flag-question',
        name: 'FlagQuestion',
        component: () => import('../views/Page/FlagQuestion.vue')
      },
      {
        path: 'lecture-video',
        name: 'LectureVideo',
        component: () => import('../views/Page/LectureVideo.vue')
      },
      {
        path: 'general-information',
        name: 'GeneralInformation',
        component: () => import('../views/Page/GeneralInformation.vue')
      },
      {
        path: 'note-audio',
        name: 'NoteAudio',
        component: () => import('../views/Page/NoteAudio.vue')
      },
      {
        path: 'activated-member',
        name: 'ActivatedMember',
        component: () => import('../views/Page/ActivatedMember.vue')
      },
      {
        path: 'comments',
        name: 'MemberComments',
        component: () => import('../views/Page/MemberComments.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/Page/Profile.vue')
      },
      {
        path: 'activation',
        name: 'ActivationKey',
        component: () => import('../views/Page/ActivationKey.vue')
      }
    ]
  }
]
const router = createRouter({
  //history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = storageService.doKeyExist()
  const isPublic = to.matched.some(rec => rec.meta.public)
  console.log(isLoggedIn)
  if (!isLoggedIn && !isPublic) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  if (isLoggedIn && isPublic) {
    return next({
      name: 'Dashboard'
    })
  }
  next()
})

export default router