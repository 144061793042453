class StorageService{
	authKey = 'lfx_client_app'
	activationKey = 'lfx_client_app_activate'

	setApiUrl () {
		//const host = window.location.hostname
		//return `http://${host}:3333/v1/admin-api`
        //console.log(host)
        //return `http://192.168.43.94:3333/v1/mobile_api`
		return 'https://api200.lfxedu.com/v1/admin-api'
    }

    setAppUrl () {
		//const host = window.location.hostname
		//return `http://${host}:3333/v1/api`
        //console.log(host)
        //return `http://192.168.43.94:3333/v1/mobile_api`
        return 'https://api200.lfxedu.com/v1/api'
    }

	getItem () {
		const ret = localStorage.getItem('lfx_panel')
		if (!ret) {
			return false
		}
		return JSON.parse(unescape(atob(ret)))
    }

	doKeyExist () {
		const ret = localStorage.getItem('lfx_panel')
		if (!ret) {
			return false
		}
		return true
	}

	storeItem (value) {
		const encryptedValue = btoa(escape(JSON.stringify(value)))
		localStorage.setItem('lfx_panel', encryptedValue)
    }

    removeItem () {
		localStorage.removeItem('lfx_panel')
    }
}
const storageService = new StorageService()
export { storageService }